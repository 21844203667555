<template>
  <div>
    <welcome-message-text class='mt-12 px-2 lg:px-0' />
    <president-image class='mt-12 px-2 lg:px-0 w-full lg:w-64' />
    <program-schedule class='mt-24 px-2 lg:px-0'/>
    <sponsors class='mt-24'/>
    <speakers class='mt-24' v-if='showSpeakers'/>
  </div>
</template>

<script>
import PresidentImage      from '@/components/welcome/PresidentImage.vue'
import WelcomeMessageText  from '@/components/welcome/WelcomeMessageText.vue'
import ProgramSchedule     from '@/components/programs/ProgramSchedule.vue'
import Sponsors            from '@/views/demo/Sponsors.vue'
import Speakers            from '@/views/demo/Speakers.vue'

export default {
  name: 'Welcome',
  components: {
    WelcomeMessageText,
    ProgramSchedule,
    Sponsors,
    Speakers,
    PresidentImage
  },
  computed: {
    showSpeakers () {
      return new URL(location.href).host.replace('www.','').split('.')[0] !== 'sicem2021'
    },
  },
}
</script>
