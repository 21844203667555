<template>
  <div>
    <div v-if='hasManySessionRunningDays'
         class='flex flex-row justify-between mb-4 lg:mb-12'>
      <div v-for='(dailySessions, index) in sessionsByDate'
           :key='`dailySessions-${dailySessions.date}`'
           class='program-date-selector'
           :class='programDateSelectorActiveClass(index)'
           @click='updateSessionsByDateIndex(index)'>
        <div>{{ dateString(dailySessions.date) }}</div>
      </div>
    </div>
    <program-by-day
      class='mt-4 lg:mt-12'
      :daily-sessions='sessionsByDate[index]'
      :show-schedule-full-width='showScheduleFullWidth'
      :program-slot-size='programSlotSize'
      :event-program-mobile-layout='eventProgramMobileLayout' />
  </div>
</template>

<script>
import ProgramByDay          from '@/components/programs/ProgramByDay.vue'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'ProgramSchedule',
  props: [
    'defaultToToday',
    'showScheduleFullWidth',
    'programSlotSize',
  ],
  data () {
    return {
      index: 0,
    }
  },
  components: {
    ProgramByDay,
  },
  watch: {
    currentQueryParam: {
      handler: function (newVal) {
        if (newVal) { 
          this.index = newVal - 1 // convert from human readable to array index
        }
      }, 
      immediate: true
    },
    sessionsDateCount: {
      handler: function (newVal) { 
        if (newVal > 1 && this.defaultToToday) { // and the default is a valid index
          // this.index = this.defaultDateIndex
          this.updateSessionsByDateIndex(this.defaultDateIndex)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('sessions', [
      'sessionsByDate',
      'hasManySessionRunningDays',
      'sessionsDateCount',
      'sessionDates',
    ]),
    ...mapGetters('events', [
      'dateLocaleString',
      'eventProgramMobileLayout',
    ]),
    defaultDateIndex () {
      let todaysDate = dayjs(new Date()).tz("Asia/Seoul").format('YYYY-MM-DD')
      let index = this.sessionDates.findIndex(date => date === todaysDate)
      if (index > 0) {
        return index
      } else {
        return 0
      } 
    },
    dayIndexHumanReadable () {
      return this.index + 1
    },
    hasProgramQuery () {
      return this.$route.query.program_day_index // this should use the human readable version
    },
    currentQueryParam () {
      return (this.hasProgramQuery) ? parseInt(this.$route.query.program_day_index) : 1
    },
  },
  methods: {
    updateSessionsByDateIndex (index) {
      if (this.index !== index) {
        this.updateProgramDayIndexQueryParam(index + 1)
      }
    },
    programDateSelectorActiveClass (index) {
      return (this.index === index) ? 'is_active' : ''
    },
    dateString (date) {
      return DateTimeStringHelpers.toDateWithoutYear(date, this.dateLocaleString)
    },
    updateProgramDayIndexQueryParam (newIndex) {
      this.$router.push({ query: {...this.$route.query, program_day_index: newIndex} })
    },
  },
}
</script>

<style type='scss' scoped>
.program-date-selector {
  @apply flex-grow border text-center py-4 text-sm font-semibold cursor-pointer;
}

.program-date-selector.is_active {
  background-color: var(--eventMainColor);
  color: #FFF;
}

@media (min-width: 1024px) {
  .program-date-selector:hover {
    background-color: var(--eventMainColor);
    color: #FFF;
  }
}
</style>
