<template>
  <div class='mt-8 lg:mt-12 w-full px-4 lg:px-0'>
    <div class='w-full px-2 lg:px-0 flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2'>
      <h1 class='text-2xl font-semibold'>{{title}}</h1>
      <div class='w-full lg:w-auto flex flex-col lg:flex-row items-start lg:items-center gap-x-1 gap-y-2'>
        <div class='relative w-full lg:w-auto'>
          <input 
            v-model='searchString'
            type='text'
            class='style-default px-2 w-full lg:w-72'
            placeholder='Search by name or affiliation' />
          <button
            v-if='searchString'
            @click='resetSearchString'>
            <x-icon 
              class='absolute text-gray-300 h-5'
              style='top: 0.4rem; right: 0.5rem;' />
          </button>
          <search-icon
            v-else
            class='absolute text-gray-300 h-5'
            style='top: 0.4rem; right: 0.5rem;' />
        </div>
        <select
          v-model='filteredBy'
          class='border px-2 py-1.5 rounded w-full lg:w-auto'>
          <option value='all'>All</option>
          <option value='domestic'>Korean</option>
          <option value='international'>International</option>
        </select>
        <select
          v-model='sortBy'
          class='border px-2 py-1.5 rounded w-full lg:w-auto'>
          <option value='last'>Sort by: Last Name</option>
          <option value='first'>Sort by: First Name</option>
        </select>
      </div>
    </div>
    <div class='mt-4 flex flex-row flex-wrap gap-4'>
      <speaker-card v-for='speaker in whichSpeakers'
        :key='`speaker-${speaker.id}`'
        :card-width='speakerCardWidth'
        :speaker='speaker'
        class='cursor-pointer standard-transition hover:bg-gray-100 hover:shadow-md'
        @click.native='openSpeakerDetails(speaker.id)' />
      <div v-if='emptySpeakers'
        class='text-center bg-gray-100 p-16 mt-8 w-full rounded-md'>
        There are no speakers that match your search and/or filter criteria.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { SearchIcon, XIcon } from '@vue-hero-icons/outline'
import cloneDeep             from 'lodash/cloneDeep'
import SpeakerCard    from '@/components/speakers/SpeakerCard.vue'

export default {
  name: 'Speakers',
  components: {
    SpeakerCard,
    SearchIcon,
    XIcon
  },
  props: {
    title: {
      type: String,
      default: 'Speakers',
    },
    speakerCardWidth: {
      type: String,
      default: '15rem',
    }
  },
  data () {
    return {
      filteredBy: 'all', // all, international, domestic
      sortBy: 'last', // first, last
      searchString: '',
    }
  },
  computed: {
    ...mapState('speakers', [
      'speakers',
    ]),
    ...mapGetters('speakers', [
      'speakersDomestic',
      'speakersInternational',
    ]),
    emptySpeakers () {
      return this.whichSpeakers.length === 0
    },
    whichSpeakerList () {
      switch (this.filteredBy) {
        case 'international':
        return this.speakersInternational
        case 'domestic':
        return this.speakersDomestic
        case 'all':
        default:
        return this.speakers
      }
    },
    whichSpeakers () {
      let speakers = this.whichSpeakerList.filter(speaker => {
        return speaker.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
               speaker.firstName.toLowerCase().includes(this.searchString.toLowerCase()) ||
               speaker.lastName.toLowerCase().includes(this.searchString.toLowerCase()) ||
               speaker.affiliation.toLowerCase().includes(this.searchString.toLowerCase())
      })
      let cloned = cloneDeep(speakers)
      return cloned.sort((a, b) => {
        const sortByA = (this.sortBy === 'last') ? a.lastName.toUpperCase() : a.firstName.toUpperCase() // ignore upper and lowercase
        const sortByB = (this.sortBy === 'last') ? b.lastName.toUpperCase() : b.firstName.toUpperCase()  // ignore upper and lowercase
        if (sortByA < sortByB) {
          return -1
        } else if (sortByA > sortByB) {
          return 1
        } else {
          return 0
        }
      })
    },
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
      'openSpeakerDetailsModal',
    ]),
    ...mapActions('speakers', [
      'getSpeakers',
    ]),
    resetSearchString () {
      this.searchString = ''
    },
    openSpeakerDetails (speakerId) {
      this.openSpeakerDetailsModal(speakerId)
    },
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    this.getSpeakers().then(() => {
      this.hideFullpageLoadingIndicator()
    })
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
}
</script>
