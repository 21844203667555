<template>
    <img :src='eventConfigPresidentImageUrl'
         class='rounded-md'>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PresidentImage',
  computed: {
    ...mapGetters('events', [
      'eventConfigPresidentImageUrl'
    ])
  }
}
</script>
