<template>
  <div 
    class='speaker-card flex flex-row lg:flex-col mx-2 lg:mx-0 items-center lg:items-stretch rounded-md lg:rounded-lg bg-gray-50'
    style='max-height:24rem;'
    :style='setupVariableWidth'>
    <div class='block w-1/3 lg:w-full h-auto rounded-l-md lg:rounded-bl-none lg:rounded-t-lg flex-shrink-0'>
      <img 
        v-show='showImage && speaker.imageUrl'
        :src='speaker.imageUrl'
        class='object-cover lg:rounded p-2 h-auto lg:mx-auto max-h-48 lg:h-36'
        @error='hideSpeakerImageIfNotAvailable'>
    </div>
    <div class='p-4 text-sm lg:text-center overflow-y-hidden my-auto' style='max-height:10rem;'>
      <div class='font-semibold text-base text-gray-900 leading-4'>{{ speaker.firstName }} {{ speaker.lastName }}</div>
      <div class='text-gray-500 leading-4'>{{ speaker.affiliation }}</div>
      <div class='text-gray-700 leading-4 text-xs uppercase mt-1'>{{ speaker.country }}</div>
      <a v-if='hasAbstract' :href='`${speaker.abstractUrl}`' class='inline-block py-1 w-24 rounded mt-2 mx-1 uppercase text-center border border-gray-300 hover:border-gray-600 hover:shadow-md cursor-pointer' target='_blank'>abstract</a>
      <a v-if='hasCv' 
        :href='`${speaker.cvUrl}`' 
        class='inline-block py-1 w-24 rounded mt-2 mx-1 uppercase text-center border border-gray-300 hover:border-gray-600 hover:shadow-md cursor-pointer' 
        target='_blank'>
        {{speakerCvLinkUrlTitle}}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SpeakerCard',
  props: [
    'speaker',
    'cardWidth',
  ],
  data () {
    return {
      showImage: true,
    }
  },
  computed: {
    ...mapGetters('events', [
      'speakerCvLinkUrlTitle',
    ]),
    hasAbstract () {
      return this.speaker.abstractUrl
    },
    hasCv () {
      return this.speaker.cvUrl
    },
    setupVariableWidth () {
      let width = (this.cardWidth) ? this.cardWidth : '15rem'
      return {
       '--cardWidth': width,
      }
    },
  },
  methods: {
    hideSpeakerImageIfNotAvailable () {
      this.showImage = false
    }
  },
}
</script>

<style lang='scss' scoped>
  .speaker-card {
    width: 100%;
  }

  @media (min-width: 1024px) { 
    .speaker-card {
      width: var(--cardWidth);
    }
  }
</style>
