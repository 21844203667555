<template>
  <div class='px-2 lg:px-0'>
    <sponsors-by-level v-for='(sponsorsByLevel, index) in sponsorList'
      :key='`sponsors-by-level-${sponsorsByLevel.levelName}`'
      :ref='`sponsor-list-container-${index}`'
      :sponsors='sponsorsByLevel'
      :configurations='configurationsByLevel(index)'
      :sizing-multiplier='sponsorsConfigurations.sizingMultiplier' />
  </div>
</template>

<script>
import { mapState, mapActions }  from 'vuex'
import SponsorsByLevel from '@/components/sponsors/SponsorsByLevel.vue'

export default {
  name: 'Sponsors',
  components: {
    SponsorsByLevel,
  },
  props: {
    requireLogin: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('sponsors', [
      'sponsorList',
    ]),
    ...mapState('events', [
      'sponsorsConfigurations',
    ]),
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('sponsors', [
      'getSponsors',
      'getSponsorsNoAuth',
    ]),
    configurationsByLevel (levelNumber) {
      return this.sponsorsConfigurations.levels.find(level => level.levelIndex === (levelNumber+1))
    },
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    if (this.requireLogin) {
      this.getSponsors('Sponsors').then(() => {
        this.hideFullpageLoadingIndicator()
      })
    } else {
      this.getSponsorsNoAuth('Sponsors').then(() => {
        this.hideFullpageLoadingIndicator()
      })
    }

  },
}
</script>

