<template>
  <div v-html='eventConfigWelcomeMessage'
    class='whitespace-pre-line'>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WelcomeMessageText',
  computed: {
    ...mapGetters('events', [
      'eventConfigWelcomeMessage'
    ])
  }
}
</script>